define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-handle", ["exports", "@glimmer/component", "discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", "@ember/component", "@ember/template-factory"], function (_exports, _component, _activityPubUtilities, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubActorHandle extends _component.default {
    get handle() {
      return (0, _activityPubUtilities.buildHandle)({
        actor: this.args.actor
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <a href={{@actor.url}} target="_blank" rel="noopener noreferrer">
          {{this.handle}}
        </a>
      
    */
    {
      "id": "sbglKZFU",
      "block": "[[[1,\"\\n    \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n      \"],[1,[30,0,[\"handle\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@actor\"],false,[]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-handle.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubActorHandle;
});