define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-attributes", ["exports", "@glimmer/component", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-attribute", "@ember/component", "@ember/template-factory"], function (_exports, _component, _activityPubAttribute, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubAttributes extends _component.default {
    get topic() {
      return this.args.topic;
    }
    get post() {
      return this.args.post;
    }
    get postActor() {
      return this.post.topic.getActivityPubPostActor(this.post.id);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-attributes">
          {{#if this.topic}}
            <ActivityPubAttribute
              @attribute="object_type"
              @value={{this.topic.activity_pub_object_type}}
              @uri={{this.topic.activity_pub_object_id}}
            />
          {{/if}}
          <ActivityPubAttribute
            @attribute="object_type"
            @value={{this.post.activity_pub_object_type}}
            @uri={{this.post.activity_pub_object_id}}
          />
          <ActivityPubAttribute
            @attribute="visibility"
            @value={{this.post.activity_pub_visibility}}
          />
          {{#if this.postActor}}
            <ActivityPubAttribute
              @attribute="actor"
              @value={{this.postActor.actor.handle}}
              @uri={{this.postActor.actor.ap_id}}
            />
          {{/if}}
        </div>
      
    */
    {
      "id": "OEAgJKZi",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-attributes\"],[12],[1,\"\\n\"],[41,[30,0,[\"topic\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@attribute\",\"@value\",\"@uri\"],[\"object_type\",[30,0,[\"topic\",\"activity_pub_object_type\"]],[30,0,[\"topic\",\"activity_pub_object_id\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[32,0],null,[[\"@attribute\",\"@value\",\"@uri\"],[\"object_type\",[30,0,[\"post\",\"activity_pub_object_type\"]],[30,0,[\"post\",\"activity_pub_object_id\"]]]],null],[1,\"\\n      \"],[8,[32,0],null,[[\"@attribute\",\"@value\"],[\"visibility\",[30,0,[\"post\",\"activity_pub_visibility\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"postActor\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@attribute\",\"@value\",\"@uri\"],[\"actor\",[30,0,[\"postActor\",\"actor\",\"handle\"]],[30,0,[\"postActor\",\"actor\",\"ap_id\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-attributes.js",
      "scope": () => [_activityPubAttribute.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubAttributes;
});