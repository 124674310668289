define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-info", ["exports", "@glimmer/component", "discourse/helpers/d-icon", "discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _activityPubUtilities, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubTopicInfo extends _component.default {
    get topic() {
      return this.args.topic;
    }
    get status() {
      return (0, _activityPubUtilities.activityPubTopicStatus)(this.topic);
    }
    get statusText() {
      return (0, _activityPubUtilities.activityPubTopicStatusText)(this.topic, {
        infoStatus: true
      });
    }
    get statusIcon() {
      if (this.status === "not_published") {
        return "far-circle-dot";
      } else {
        return this.topic.activity_pub_local ? "circle-arrow-up" : "circle-arrow-down";
      }
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-topic-info">
          <span class="activity-pub-topic-status">{{dIcon
              this.statusIcon
            }}{{this.statusText}}</span>
        </div>
      
    */
    {
      "id": "KwZB4es4",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-topic-info\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"activity-pub-topic-status\"],[12],[1,[28,[32,0],[[30,0,[\"statusIcon\"]]],null]],[1,[30,0,[\"statusText\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-info.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubTopicInfo;
});