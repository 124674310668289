define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-status", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/service", "@ember/string", "discourse/helpers/d-icon", "discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-topic-info", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _service, _string, _dIcon, _activityPubUtilities, _activityPubTopicInfo, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubTopicStatus extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    get topic() {
      return this.args.topic;
    }
    get statusText() {
      return (0, _activityPubUtilities.activityPubTopicStatusText)(this.topic);
    }
    get status() {
      return (0, _activityPubUtilities.activityPubTopicStatus)(this.topic);
    }
    get icon() {
      return this.status === "not_published" ? "discourse-activity-pub-slash" : "discourse-activity-pub";
    }
    get classes() {
      let placeClass = this.topic.activity_pub_local ? "local" : "remote";
      return `activity-pub-topic-status ${(0, _string.dasherize)(this.status)} ${placeClass}`;
    }
    async showInfoModal() {
      const topic = this.topic;
      const firstPost = await topic.firstPost();
      this.modal.show(_activityPubTopicInfo.default, {
        model: {
          topic,
          firstPost
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "showInfoModal", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          role="button"
          class={{this.classes}}
          title={{this.statusText}}
          {{on "click" this.showInfoModal}}
        >
          {{dIcon this.icon}}
          {{this.statusText}}
        </div>
      
    */
    {
      "id": "B1pnwe3o",
      "block": "[[[1,\"\\n    \"],[11,0],[24,\"role\",\"button\"],[16,0,[30,0,[\"classes\"]]],[16,\"title\",[30,0,[\"statusText\"]]],[4,[32,0],[\"click\",[30,0,[\"showInfoModal\"]]],null],[12],[1,\"\\n      \"],[1,[28,[32,1],[[30,0,[\"icon\"]]],null]],[1,\"\\n      \"],[1,[30,0,[\"statusText\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-status.js",
      "scope": () => [_modifier.on, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubTopicStatus;
});