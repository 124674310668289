define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-handle-link", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubActorHandleLink = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <a href={{@actor.url}} target="_blank" rel="noopener noreferrer">
      {{@actor.handle}}
    </a>
  
  */
  {
    "id": "Jf0nS/Rh",
    "block": "[[[1,\"\\n  \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[1,[30,1,[\"handle\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@actor\"],false,[]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-handle-link.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "activity-pub-actor-handle-link:ActivityPubActorHandleLink"));
  var _default = _exports.default = ActivityPubActorHandleLink;
});