define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-admin-info", ["exports", "@glimmer/component", "discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", "@ember/component", "@ember/template-factory"], function (_exports, _component, _activityPubUtilities, _activityPubHandle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubPublicationInfo extends _component.default {
    get actors() {
      return (0, _activityPubUtilities.activityPubTopicActors)(this.args.topic);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-admin-info">
          <div class="activity-pub-admin-info-actors">
            {{#each this.actors as |actor|}}
              <ActivityPubHandle @actor={{actor}} @hideCopy={{true}} />
            {{/each}}
          </div>
        </div>
      
    */
    {
      "id": "wAPEn6oI",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-admin-info\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-admin-info-actors\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"actors\"]]],null]],null],null,[[[1,\"          \"],[8,[32,0],null,[[\"@actor\",\"@hideCopy\"],[[30,1],true]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"actor\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-admin-info.js",
      "scope": () => [_activityPubHandle.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubPublicationInfo;
});